/**
 * filename: gum.js
 *
 * This is the main script for the gum website
 */

// import '@fontsource-variable/noto-sans-hk';
// import '@fontsource-variable/noto-serif-hk';
import '@fontsource-variable/quicksand';
import '@fontsource/zen-maru-gothic/';
import '@fontsource/kosugi-maru';

import '../css/shared.css';
import '../scss/gum.scss';
import * as bootstrap from 'bootstrap'

const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
